<template>
    <div class="claim-box">
        <div class="imgtop">
            <picture>
                <source media="(max-width:575px)" srcset="../../image/claim/claimtop-2.png">
                <source media="(max-width:991px)" srcset="../../image/claim/claimtop-1.png">
                <img src="../../image/claim/claimtop.png" alt="">
            </picture>
            <div class="textbox">
                <div>
                    <p>{{ title[0] }}</p>
                    <p>{{ title[1] }} - 理赔教程</p>
                    <!-- <p class="check">拍照提交，无需邮寄</p> -->
                     <p class="check" v-if="check">{{check}}</p>
                </div>
            </div>
        </div>
        <div class="conbox">
            <div class="item">
                <div class="left">
                    <span>1</span>
                </div>
                <div class="right">
                    <p class="tit">报案须知</p>
                    <div class="con">
                        <p>1、出险时间<span>{{ report.report_time }}小时</span>以内。</p>
                        <p>2、救治医院级别，<span>{{ report.hospital_level }}</span>。</p>
                        <p>3、若涉及伤残，<span>{{ report.restore_time }}天</span>以后，可去有资质的司法鉴定中心，进行伤残鉴定。</p>
                        <p v-if="report.second_status">4、如有二次治疗但已经过了保险期限，二次治疗时间限制在自事发之日起，<span>{{ report.second_time }}天</span>之内的合理费用。</p>
                        <p class="col66" style="font-size:14px"><span>注：发生保险责任事故时，保险人将按照保险合同签发地政府颁布的基本医疗保险报销范围审核医疗费用并进行赔偿，理赔中免赔金额和报销比例详见保单中的特别约定条款。</span> </p>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="left">
                    <span>2</span>
                </div>
                <div class="right">
                    <p class="tit">报案资料</p>
                    <div class="con">
                        <p class="bold">保单号、出险人员、出险人员身份证、出险时间、出险地点、联系电话、救治医院、受伤部位、事故经过等信息。</p>
                        <p class="mb5">描述样例：</p>
                        <p class="col66">保单号为xxxxxxx我单位员工xxx身份证号xxxxxxxx，于xx时间xx地点意外受伤（描述事故经过及受伤部位），随即送往xx医院（{{ report.hospital_level }}）进行救治。</p>
                    </div>
                </div>
            </div>
            <div class="item" v-if="supplement.length>0">
                <div class="left">
                    <span>3</span>
                </div>
                <div class="right">
                    <p class="tit">报案补充资料</p>
                    <div class="con">
                        <ul class="hdp">
                            <li v-for="(v,i) in supplement" :key="i">• {{v.name}} <span v-if='v.original_status'>({{original_status[v.original_status]}})</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="left">
                    <span v-if="supplement.length>0">4</span>
                     <span v-else>3</span>
                </div>
                <div class="right">
                    <p class="tit">理赔资料</p><a v-if="file_url!=''" :href=" file_url " class="dow" target="_blank" rel="noopener noreferrer" download>下载模板</a>
                    <div class="con litit">
                        <div v-for="(v,i) in cate" :key="i">
                            <p class="bold" >{{ ++i }}、{{ v.title }}</p>
                            <p class="col66" v-html="v.set_list"></p>
                            <!-- <p class="col66" >{{v.set_list}}</p> -->
                        </div>
                        <div v-if="special&&special.length>0">
                            <p class="bold">{{ ++cate.length }}、特殊资料</p>
                            <p class="col66" v-for="(v,i) in special" :key="i">若涉及到{{v.type_name}}，需要提供{{v.type_list}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    
    export default {
        name: "claimdata",
       
        data() {
            return {
                original_status:{
                  1:'原件',
                  2:'复印件',
                  3:'复印件加盖公章'  
                },
                company_id:"",
                status:"",
                title:[],//标题字符串拆为数组
                check:'',//顶部大图黄按钮文字
                report:{},//报案要求
                supplement:[],//报案补充资料
                cate:"",// 理赔资料
                special:"",//特殊资料
                file_url:""// 下载模板
            }
        },
        mounted() {
            this.company_id = this.GetQueryString("company_id")
            this.status = this.GetQueryString("status")
            this.get(this.company_id,this.status);            
        },
        methods: {
            get:function(company_id,status){
                let that=this;
                let loading=that.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                 let _data={
                    company_id:company_id,
                    status:status
                }
            //    let data = new FormData();
            //     data.append('company_id',company_id);
            //     data.append('status',status);
           
                that.axios({
                    url: this.$store.state.baseUrl + '/api/Claimconfig/Claimscheme/schemeDetail',
                    method: 'GET',
                    //  method: 'POST',
                    params:_data,
                }).then(function(res) {
                    console.log(res)
                    loading.close();
                    if(res.data.code==200){
                        that.title=res.data.data.title.split('-')
                        that.check=res.data.data.check
                        that.report=res.data.data.report
                        that.supplement=res.data.data.supplement
                        that.cate=res.data.data.cate
                        that.special=res.data.data.special
                        that.file_url=res.data.data.file_url
                    }else{
                        that.$message({message: res.msg, type: 'error'}); 
                    }
                   
                }).catch(function (error) {
                    console.log(error);
                    that.$message({message: error, type: 'error'}); 
                });
            },
           GetQueryString:function(name) {
                var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
                var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
                var context = "";
                if (r != null)
                context = r[2];
                reg = null;
                r = null;
                return context == null || context == "" || context == "undefined" ? "" : context;
            }
        },
    }
</script>
<style scoped lang="scss">
    div{
        box-sizing: border-box;
        -webkit-print-color-adjust: exact;
    }
    .bold{
        font-weight: bold!important;
    }
    .col66{
        color: #666!important;
    }
    ul{
        margin: 0;
        padding:0;
        list-style: none;
    }
    .claim-box{
        max-width: 1920px;
        margin: 0 auto;
        background:#3C81DA;
    }
    .claim-box .imgtop{
        position: relative;
        height: 680px;
        overflow: hidden;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
         .textbox{
            position: absolute;
            top:0;
            left:0;
            height: 100%;
            width: 100%;            
        }
        .textbox>div{
            width: 1200px;
            margin:150px auto 0;
            text-align: left;
            .check{
                font-size: 28px;
                color: #fff;
                padding:12px 25px;
                // font-weight: 400;
                margin-top:20px;
                display: inline-block;                
                background: linear-gradient(0deg, #FF9500, #FF6B00);
                border: 2px solid #FFCF2C;
                border-radius: 28px;
                text-shadow: none;
            }
        }
        .textbox p{
            color: #fff;
            font-size: 70px;
            font-weight: bold;
            margin: 0;
            text-shadow: #144671b3 0.1em 0.1em 0.1em;
            text-align: left;
        }
    }
    
    .claim-box .conbox{
        width: 1200px;
        margin:-185px auto 0;      
        background: #FFFFFF;
        border-radius: 10px 10px 0px 0px;
        padding: 70px 50px;
        position: relative;
        overflow: hidden;

        .item{
            display: flex;
            justify-content: flex-start;

            .left{
                width:27px;
                position: relative;
                top: 8px;
                
                span{
                    width: 21px;
                    height: 21px;
                    background: #FFFFFF;
                    border: 3px solid #3C81DA;
                    border-radius: 50%;
                    font-size: 16px;
                    font-weight: bold;
                    color: #3C81DA;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .left:after{
                content: "";
                display: block;
                width: 1px;
                height: 100%;
                position: absolute;
                top:27px;
                left: 13px;
                background:#3C81DA;
            }
            .right{
                flex:1;
                margin-left: 25px;
                text-align: left;

                .tit{
                    display: inline-block;
                    background: linear-gradient(4deg, #20B9ED, #0872E8);
                    border-radius: 21px;
                    margin: 0 0 20px;
                    padding: 5px 20px;
                    font-size: 24px;
                    font-weight: 400;
                    color: #FFFFFF;
                }
                .dow{                    
                    font-size: 15px;
                    font-weight: bold;
                    text-decoration: none;
                    display: inline-block;
                    margin-left: 15px;
                    color:#D44B39;
                    -webkit-print-color-adjust: exact;
                }
                .con{
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333; 

                    p{
                        margin: 0 0 15px;

                        span{
                            color:#D44B39;
                        }
                    }                    
                    .mb5{
                        margin-bottom: 5px;
                    }
                    .hdp{
                        display: flex;
                        justify-content: flex-start;
                        flex-wrap:wrap;
                         
                        li{
                            margin-right: 30px;
                            font-size: 16px;
                            font-weight: bold;
                            color: #333333;
                            line-height: 1.6;
                            
                            span{
                                font-weight: 400;
                                // color: #D44B39;
                            }
                        }
                    }
                    
                }
                .con.litit{
                    .bold{
                        margin-bottom: 8px;
                    }
                }
            }
        }
        .item+.item{
            margin-top:30px;
        }
    }
    @media screen and (max-width: 1200px){
        .claim-box .imgtop{
            .textbox>div{
                width: 991px;
                margin:190px auto 0;
            }
        }
        .claim-box .conbox{
            width: 991px;
        }
    }
     @media screen and (max-width: 991px){
        .claim-box .imgtop{
            .textbox>div{               
                width: calc(100% - 30px);
                margin:170px auto 0;
                .check{
                    font-size: 18px;
                    padding:10px 20px;
                    margin-top:20px;
                    border: 1px solid #FFCF2C;
                }
                p{
                    font-size:60px;
                    margin-left: 80px;
                }
            }
        }
        .claim-box .conbox{
            width: calc(100% - 30px);
            padding: 30px 15px;

            .item .left{
                width: 17px;
                span{
                    width: 15px;
                    height: 15px;
                    border: 1px solid #3C81DA;
                    font-size: 12px;
                } 
            }
            .item .left:after {
                top: 17px;
                left: 8px;
            }
            .item .right{
                margin-left: 15px;

                .tit{
                    margin: 0 0 15px;
                    padding: 4px 10px;
                    font-size: 15px;
                }
                .con{
                    font-size: 14px;
                    p{
                        margin:0 0 10px;
                    }
                    .hdp {
                        margin-bottom: 5px;
                    }
                    .hdp li[data-v-c22ca354] {
                        margin-right: 30px;
                        font-size: 14px;
                    }
                }
                 .dow[data-v-c22ca354] {
                    font-size: 12px;
                    padding: 5px;
                    margin-left: 10px;
                 }
            }
            .item + .item{
                margin-top: 10px;
            }
        }
    }
    @media screen and (max-width: 575px){
        .claim-box .imgtop{
            height: 450px;
            .textbox>div{
                margin:120px auto 0;
                
                .check{
                    font-size: 13px;
                    padding:5px 15px;
                    margin-top:15px;
                }
                 p{
                    font-size: 30px;
                    margin-left: 25px;
                }
            }
            
        }
        .claim-box .conbox{
            margin: -140px auto 0;
        }
    }
</style>
